import { render, staticRenderFns } from "./DocMarkdown.vue?vue&type=template&id=7b791816&"
import script from "./DocMarkdown.vue?vue&type=script&lang=js&"
export * from "./DocMarkdown.vue?vue&type=script&lang=js&"
import style0 from "./DocMarkdown.vue?vue&type=style&index=0&id=7b791816&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports